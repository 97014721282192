import React from "react";
import { Army, ArmyState, Nationality, Trench } from "./utils/Battle";
import GermanArmyFresh from "./img/GE_Army.png";
import GermanArmySpent from "./img/GE_Army_Spent.png";
import BritishArmyFresh from "./img/BR_Army.png";
import BritishArmySpent from "./img/BR_Army_Spent.png";

export interface PieceProps {
  piece: Army | Trench;
}

interface ImageInfo {
  src: string;
  alt: string;
}

const imageTags = {
  Army: {
    [Nationality.GERMAN]: {
      [ArmyState.FRESH]: GermanArmyFresh,
      [ArmyState.SPENT]: GermanArmySpent,
    },
    [Nationality.BRITISH]: {
      [ArmyState.FRESH]: BritishArmyFresh,
      [ArmyState.SPENT]: BritishArmySpent,
    },
  },
  // TODO: fix me
  Trench: {
    [Nationality.GERMAN]: {
      [ArmyState.FRESH]: GermanArmyFresh,
      [ArmyState.SPENT]: GermanArmySpent,
    },
    [Nationality.BRITISH]: {
      [ArmyState.FRESH]: BritishArmyFresh,
      [ArmyState.SPENT]: BritishArmySpent,
    },
  },
};

function stateString(piece: Army | Trench): string {
  switch (piece.state) {
    // todo : change text for trench
    case ArmyState.FRESH:
      return "Fresh";
    case ArmyState.SPENT:
      return "Spent";
  }
}

function nationalityString(piece: Army | Trench): string {
  switch (piece.nationality) {
    case Nationality.GERMAN:
      return "German";
    case Nationality.BRITISH:
      return "British";
  }
}

function typeString(piece: Army | Trench): string {
  return piece.type;
}

function imageInfo(piece: Army | Trench): ImageInfo {
  return {
    src: imageTags[piece.type][piece.nationality][piece.state],
    alt: `${stateString(piece)} ${nationalityString(piece)} ${typeString(
      piece
    )}`,
  };
}

export default class Piece extends React.Component<PieceProps> {
  render() {
    const info = imageInfo(this.props.piece);

    return <img src={info.src} alt={info.alt} />;
  }
}
