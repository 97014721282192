import React from "react";

export interface ButtonClickFn {
  (): void;
}

export interface ButtonProps {
  addlClass?: string;
  onClick?: ButtonClickFn;
  enabled?: boolean;
  text: string;
}

class Button extends React.Component<ButtonProps, {}> {
  render() {
    const enabled = this.props.enabled === undefined || this.props.enabled;
    const classes = `bg-blue-500 hover:bg-blue-700 disabled:bg-gray-500 text-white font-bold py-2 px-4 rounded-full ${
      this.props.addlClass || ""
    }`;

    return (
      <button
        disabled={!enabled}
        className={classes}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </button>
    );
  }
}

export default Button;
