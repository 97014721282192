import { roll } from "./Dice";

export enum ArmyState {
  FRESH = "FRESH",
  SPENT = "SPENT",
}

export enum BattleState {
  IN_PROGRESS,
  ATTACKER_WON,
  DEFENDER_WON,
}

export const enum Nationality {
  GERMAN = "GERMAN",
  BRITISH = "BRITISH",
}

export interface Piece {
  id: string;
  state: ArmyState;
  drm: number;
  nationality: Nationality;
}

export interface Army extends Piece {
  type: "Army";
}

export interface Trench extends Piece {
  type: "Trench";
}

export interface RoundResult {
  attacker: Army;
  defender: Army | Trench;
  attackerRoll: number;
  defenderRoll: number;
  attackerFlipped: boolean;
  defenderFlipped: boolean;
  bigPushActive: boolean;
  battleState: BattleState;
}

export interface CanRunRoundResult {
  canRunRound: boolean;
  message?: string;
}

export class Battle {
  private _attackerInfo: Army[];
  private _defenderInfo: (Army | Trench)[];
  private _bigPush: boolean;
  private _battleState: BattleState;

  constructor(attackerInfo: Array<Army>, defenderInfo: Array<Army | Trench>) {
    this._attackerInfo = attackerInfo;
    this._defenderInfo = defenderInfo;
    this._bigPush = false;
    this._battleState = BattleState.IN_PROGRESS;

    if (this.attackersSpent() || this.defendersSpent()) {
      throw new Error(
        "Can't create a battle if all attackers or defenders are spent!"
      );
    }
  }

  /**
   * Add a new attacker to the battle.
   * @param attacker
   */
  addAttacker(attacker: Army) {
    if (this.isOver()) {
      throw new Error("Can't add an attacker to a battle that has ended");
    }

    this._attackerInfo.push(attacker);
  }

  /**
   * Add a new defender to the battle.
   */
  addDefender(defender: Army | Trench) {
    if (this.isOver()) {
      throw new Error("Can't add a defender to a battle that has ended");
    }

    this._defenderInfo.push(defender);
  }

  /**
   * Check whether we can run a new round. (Hint to the UI).
   */
  canRunRound(): CanRunRoundResult {
    if (this.isOver()) {
      return {
        canRunRound: false,
        message: "Battle is over",
      };
    }

    if (this._attackerInfo.length === 0) {
      return {
        canRunRound: false,
        message: "No attackers present",
      };
    }

    if (this._defenderInfo.length === 0) {
      return {
        canRunRound: false,
        message: "No defenders present",
      };
    }

    return {
      canRunRound: true,
    };
  }

  /**
   * Run one round of battle and return the result. This object is updated
   * with the new battle state as well.
   * @returns
   */
  oneRound(): RoundResult {
    const roundCheck = this.canRunRound();
    if (!roundCheck.canRunRound) {
      throw new Error(roundCheck.message || "Cannot run new round");
    }

    const attacker = this.selectAttacker();
    const defender = this.selectDefender();

    const attackerRoll = roll() + attacker.drm + (this._bigPush ? 1 : 0);
    const defenderRoll = roll() + defender.drm;

    const defenderFlipped = attackerRoll >= defenderRoll;
    if (defenderFlipped) {
      if (defender.state === ArmyState.SPENT) {
        // if the defender we picked was already spent and lost again,
        // then combat is over
        this._battleState = BattleState.ATTACKER_WON;
      } else {
        defender.state = ArmyState.SPENT;
      }
    }

    this._bigPush = defenderFlipped;

    // if we won against a trench, attacker stays fresh. otherwise flips.
    const attackerStaysFresh = defenderFlipped && defender.type === "Trench";
    if (!attackerStaysFresh) {
      attacker.state = ArmyState.SPENT;

      // and, if no fresh attackers left, then the defender has won!
      if (
        this._battleState === BattleState.IN_PROGRESS &&
        this.attackersSpent()
      ) {
        this._battleState = BattleState.DEFENDER_WON;
      }
    }

    return {
      attacker: attacker,
      defender: defender,
      attackerRoll: attackerRoll,
      defenderRoll: defenderRoll,
      attackerFlipped: !attackerStaysFresh,
      defenderFlipped: defenderFlipped,
      bigPushActive: this._bigPush,
      battleState: this.battleState(),
    };
  }

  /**
   * Return the current state of the battle.
   */
  battleState(): BattleState {
    return this._battleState;
  }

  /**
   * Check whether the battle is over.
   * @returns true if battle is over, false if not.
   */
  isOver(): boolean {
    return this.battleState() !== BattleState.IN_PROGRESS;
  }

  private attackersSpent(): boolean {
    return this._attackerInfo.every((a) => a.state === ArmyState.SPENT);
  }

  private defendersSpent(): boolean {
    return this._defenderInfo.every((d) => d.state === ArmyState.SPENT);
  }

  /**
   * Select an attacker for the next round. Just picks a fresh one at random;
   * we don't take DRMs into account for now.
   * @returns
   */
  private selectAttacker(): Army {
    const attacker = this._attackerInfo.find(
      (a) => a.state === ArmyState.FRESH
    );
    if (!attacker) {
      throw new Error("no valid attackers exist!");
    }

    return attacker;
  }

  /**
   * Select a defender for the next round. Per rules, picks trenches, then
   * fresh armies, then spent ones. Does not select taking DRMs into account
   * (although I think all DRMs for defenders are usually the same?)
   */
  private selectDefender(): Army | Trench {
    const freshTrench = this._defenderInfo.find(
      (d) => d.type === "Trench" && d.state === ArmyState.FRESH
    );
    if (freshTrench) {
      return freshTrench;
    }

    const freshArmy = this._defenderInfo.find(
      (d) => d.type === "Army" && d.state === ArmyState.FRESH
    );
    if (freshArmy) {
      return freshArmy;
    }

    const spentArmy = this._defenderInfo.find(
      (d) => d.type === "Army" && d.state === ArmyState.SPENT
    );
    if (spentArmy) {
      return spentArmy;
    }

    throw new Error("Couldn't find valid defender!");
  }
}
