import React from "react";
import BattleSide from "./BattleSide";
import { Army, ArmyState, Nationality, Trench } from "./utils/Battle";
import { v4 } from "uuid";
import Button from "./primitives/Button";
import Spinner from "./primitives/Spinner";

interface BattleSimulationState {
  attackers: Array<Army>;
  defenders: Array<Army | Trench>;
  attacker_wins?: number;
  defender_wins?: number;
  processing: boolean;
}

class BattleSimulation extends React.Component<{}, BattleSimulationState> {
  state: BattleSimulationState = {
    attackers: [],
    defenders: [],
    processing: false,
  };
  private _worker: Worker;

  constructor(props: {}) {
    super(props);
    this._worker = new Worker(new URL("./worker.js", import.meta.url));
    this._worker.onmessage = (e) => {
      this.setState((prevState) => {
        return {
          ...prevState,
          processing: false,
          attacker_wins: e.data.attacker_wins,
          defender_wins: e.data.defender_wins,
        };
      });
      console.log("Worker: " + e.data);
    };
  }

  addAttacker() {
    const newAttacker: Army = {
      type: "Army",
      nationality: Nationality.GERMAN,
      drm: 0,
      state: ArmyState.FRESH,
      id: v4(),
    };

    this.setState((prevState) => {
      return {
        attackers: [...prevState.attackers, newAttacker],
        defenders: prevState.defenders,
      };
    });
  }

  addDefendingArmy() {
    const newDefender: Army = {
      type: "Army",
      nationality: Nationality.BRITISH,
      drm: 0,
      state: ArmyState.FRESH,
      id: v4(),
    };

    this.setState((prevState) => {
      return {
        attackers: prevState.attackers,
        defenders: [...prevState.defenders, newDefender],
      };
    });
  }

  fight() {
    const attackers = this.state.attackers;
    const defenders = this.state.defenders;

    this._worker.postMessage({
      attackers: attackers,
      defenders: defenders,
    });

    this.setState((prevState) => {
      return {
        ...prevState,
        processing: true,
      };
    });
  }

  battleInfo() {
    if (
      this.state.attacker_wins === undefined ||
      this.state.defender_wins === undefined
    ) {
      return <div />;
    }

    const attacker_pct = Math.round(
      (100 * this.state.attacker_wins) /
        (this.state.attacker_wins + this.state.defender_wins)
    );

    const defender_pct = Math.round(
      (100 * this.state.defender_wins) /
        (this.state.defender_wins + this.state.attacker_wins)
    );

    return (
      <div>
        <p>
          Attacker wins: {this.state.attacker_wins} ({attacker_pct}%)
        </p>
        <p>
          Defender wins: {this.state.defender_wins} ({defender_pct}%)
        </p>
      </div>
    );
  }

  render() {
    const hasArmies =
      this.state.attackers.length > 0 && this.state.defenders.length > 0;

    const battleInfo = this.battleInfo();

    const fight = this.state.processing ? (
      <Spinner />
    ) : (
      <div className="flex items-center justify-center m-1">
        <Button
          text="Fight!"
          enabled={hasArmies}
          onClick={() => this.fight()}
        />
      </div>
    );

    return (
      <div>
        <div className="flex m-1">
          <div className="w-1/2">
            <Button onClick={() => this.addAttacker()} text="Add Attacker" />
          </div>
          <div className="w-1/4">
            <Button
              onClick={() => this.addDefendingArmy()}
              text="Add Defending Army"
            />
          </div>
          <div className="w-1/4">
            <Button text="Add Defending Trench" />
          </div>
        </div>

        <div className="flex m-1">
          <div className="w-1/2">
            <BattleSide side="Attacker" pieces={this.state.attackers} />
          </div>
          <div className="w-1/2">
            <BattleSide side="Defender" pieces={this.state.defenders} />
          </div>
        </div>

        {battleInfo}

        {fight}
      </div>
    );
  }
}

export default BattleSimulation;
