import React from "react";
import Piece from "./Piece";
import { Army, Trench } from "./utils/Battle";

export interface BattleSideProps {
  side: string;
  pieces: Array<Army | Trench>;
}

class BattleSide extends React.Component<BattleSideProps, {}> {
  render() {
    const pieces = this.props.pieces.map((piece) => (
      <div key={piece.id} className="p-2 flex-none">
        <Piece piece={piece} />
      </div>
    ));
    return (
      <div className="relative flex flex-wrap border-2 border-slate-400 min-h-[30vh] rounded-md m-1">
        <p className="text-xl font-bold absolute top-0 left-0 p-2">
          {this.props.side}
        </p>
        {pieces}
      </div>
    );
  }
}

export default BattleSide;
