import React from "react";

class Spinner extends React.Component {
  render() {
    return (
      <div className="flex justify-center items-center">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden">Processing...</span>
        </div>
      </div>
    );
  }
}

export default Spinner;
