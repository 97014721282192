import React from "react";
import BattleSimulation from "./BattleSimulation";
import { Army, Nationality, ArmyState } from "./utils/Battle";

interface AppState {
  attackers: Army[];
}

class App extends React.Component<{}, AppState> {
  state: AppState = {
    attackers: [
      {
        id: "attacker1",
        state: ArmyState.FRESH,
        drm: 0,
        type: "Army",
        nationality: Nationality.GERMAN,
      },
      {
        id: "attacker2",
        state: ArmyState.FRESH,
        drm: 0,
        type: "Army",
        nationality: Nationality.GERMAN,
      },
    ],
  };

  render() {
    return (
      <React.StrictMode>
        <div className="container mx-auto">
          <header className="text-center bg-sky-500">
            <p className="font-bold">Lamps Are Going Out</p>
          </header>
          <div>
            <BattleSimulation />
          </div>
        </div>
      </React.StrictMode>
    );
  }
}

export default App;
